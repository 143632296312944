import './Footer.css';
import '../../index.css';
import { ReactComponent as Logo } from '../../assets/svg/finb-logo.svg';
import LinkedInIcon from '../../assets/linkedin';
import FacebookIcon from '../../assets/fcb';
import InstagramIcon from '../../assets/insta';
import TwitterIcon from '../../assets/twitter';
import PhoneIcon from '../../assets/ic-phone';
import EmailIcon from '../../assets/ic-email';
import { useContactForm } from '../ContactSupport/ContactFormContext';
import useScreenSize from '../../hooks/useScreenSize';
import { isSp } from '../../utils/isSp'
import { useConfig } from "../../config/ConfigContext";

const Footer = () => {
    const { handleOpenContactForm } = useContactForm();
    const { isMobile, isTablet } = useScreenSize();
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleGoToHomepage = () => {
        window.location.href = "/";
    };

    const isSpCheck = isSp();

    const { subDomain, spLogo, accentColor, privacyPolicyDocument, termsOfServiceDocument } = useConfig();

    const defaultIconStyle = {
        fill: isSpCheck ? (accentColor || '#286684') : '#286684',
    };

    return (
        <div className="footer-section">
            <div className="footer-line"></div>
            <div className="footer">
                <div className="logo" onClick={handleGoToHomepage}>
                    {isSpCheck && spLogo ? (
                        <img src={spLogo} alt="SP Logo" className="custom-logo" />

                    ) : (
                        <Logo className="logo" />
                    )}
                </div>

                {isSpCheck ? (
                    <div className="footer-links-section-sp">
                        <a
                            className="footer-mobile-link-small-title"
                            href="javascript:void(0)"
                        >
                            Pages
                        </a>

                        <a href="/terms" className="footer-links">Terms of Service</a>
                        <a href="/privacy" className="footer-links">Privacy Policy</a>
                        <a href="/cookie" className="footer-links">Cookies</a>

                        {termsOfServiceDocument && (
                            <a href={termsOfServiceDocument} className="footer-links">
                                {subDomain} Terms of Service
                            </a>
                        )}

                        {privacyPolicyDocument && (
                            <a href={privacyPolicyDocument} className="footer-links">
                                {subDomain} Privacy Policy
                            </a>
                        )}
                    </div>
                ) : (
                    <div className="footer-links-section">
                        <a
                            className="footer-mobile-link-small-title"
                            href="javascript:void(0)"
                        >
                            Pages
                        </a>

                        <a href="/terms" className="footer-links">Terms of Service</a>
                        <a href="/privacy" className="footer-links">Privacy Policy</a>
                        <a href="/cookie" className="footer-links">Cookies</a>


                    </div>

                )}

                {isSpCheck ? (
                    <>
                        {!isMobile && !isTablet && (
                            <div className="socials-section-footer">
                                <div className="socials-section-footer">
                                    <a
                                        className="footer-mobile-link-small"
                                        href="javascript:void(0)"
                                    >
                                        Get in touch
                                    </a>
                                    <button className="global-button" onClick={() => handleOpenContactForm('pre-register')}>Send a message</button>
                                </div>
                            </div>

                        )}

                    </>
                ) : (
                    <div className="socials-section-footer">
                        <a
                            className="footer-mobile-link-small-title"
                            href="javascript:void(0)"
                        >
                            Socials
                        </a>
                        <div className="socials-icons">
                            <a
                                href="https://www.facebook.com/people/FinBursa/61573694710495/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FacebookIcon className="social-icon" style={defaultIconStyle} />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/finbursa"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkedInIcon className="social-icon" style={defaultIconStyle} />
                            </a>
                            <a
                                href="https://www.instagram.com/finbursa/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <InstagramIcon className="social-icon" style={defaultIconStyle} />
                            </a>
                            <a
                                href="https://x.com/finbursa"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <TwitterIcon className="social-icon" style={defaultIconStyle} />
                            </a>
                        </div>

                        {!isMobile && !isTablet && (
                            <div className="socials-section-footer">
                                <a
                                    className="footer-mobile-link-small-title"
                                    href="javascript:void(0)"
                                >
                                    Get in touch
                                </a>
                                <button className="global-button" onClick={() => handleOpenContactForm('pre-register')}>Send a message</button>
                            </div>
                        )}

                    </div>
                )}



                {(isMobile || isTablet) && (
                    <div className="socials-section-footer">
                        <a
                            className="footer-mobile-link-small"
                            href="javascript:void(0)"
                        >
                            Get in touch
                        </a>
                        <button className="global-button" onClick={() => handleOpenContactForm('pre-register')}>
                            Send a message
                        </button>
                    </div>
                )}

                {isSpCheck ? (
                    <></>
                ) : (
                    <div className="socials-section-footer">
                        <a
                            className="footer-mobile-link-small-title"
                            href="javascript:void(0)"
                        >
                            Contacts
                        </a>
                        <p className='footer-contact-text'>Finbursa Technologies ltd. <br />
                        Innovation One, Office 105, <br />
                        DIFC, United Arab Emirates <br />
                        PO BOX 507211 Dubai UAE </p>

                        <div className='footer-contact-links-section'>
                            <div className='footer-contact-links'>
                                <div><PhoneIcon style={defaultIconStyle} /></div>
                                <p className="footer-contact-text">0097144019452</p>
                            </div>
                            <div className='footer-contact-links'>
                                <div><EmailIcon style={defaultIconStyle} /></div>
                                <a href="mailto:support@finbursa.com" className="footer-contact-text">
                                    support@finbursa.com
                                </a>
                            </div>


                        </div>
                    </div>
                )}





            </div>
            <p className='copyright'>Copyright © 2025 Finbursa Technologies ltd. | All rights reserved.</p>
        </div>
    );
};

export default Footer;
